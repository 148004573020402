import { Link } from 'react-router-dom';
import brand_logo_white from '../../assets/images/zaf_brand/zaf_logo_white.png';

const Footer = () => {

    return (
        <>
            {/* footer::begin */}
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <div className="footer-logo-img">
                                    <img src={brand_logo_white} alt="footer-logo" draggable="false" />
                                </div>
                            </div>
                            <div className="footer-contact-info">
                                <ul>
                                    <li><i className="fa fa-map-marker-alt"></i>AL Muteena, UAE-United Arab Emirates</li>
                                    <li><a href="tel:+977-61-570898" rel="noreferrer"><i className="fa fa-phone"></i>+971 50 353 9661</a></li>
                                    <li><a href="https://www.gmail.com/" target="_blank" rel="noreferrer"><i className="fa fa-envelope"></i>info@alnadadubai.com</a></li>
                                </ul>
                            </div>
                            <div className="footer-social-icons">
                                <ul>
                                    <Link to="https://www.facebook.com/profile.php?id=100093297050706" target="_blank">
                                        <li>
                                            <i className="fab fa-facebook-f"></i>
                                        </li>
                                    </Link>
                                    <Link to="https://www.instagram.com/alnadafacility/" target="_blank">
                                        <li>
                                            <i className="fab fa-instagram"></i>
                                        </li>
                                    </Link>
                                    <Link to="https://www.tiktok.com/@alnadafacilityman" target="_blank">
                                        <li>
                                            <i className="fab fa-tiktok"></i>
                                        </li>
                                    </Link>
                                    <Link to="https://www.linkedin.com/in/zaweyat-alnada-facility-management-5555a6279/" target="_blank">
                                        <li>
                                            <i className="fab fa-linkedin-in"></i>
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-links">
                                <h3>QUICK LINKS</h3>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Home</Link></li>
                                    <li><Link to="/about"><i className="fa fa-angle-right"></i>About Us</Link></li>
                                    <li><Link to="/contact"><i className="fa fa-angle-right"></i>Contact US</Link></li>
                                    <li><Link to="/gallery"><i className="fa fa-angle-right"></i>Gallery</Link></li>
                                    <li><Link to="/application"><i className="fa fa-angle-right"></i>Apply Now</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-links">
                                <h3>SERVICES</h3>
                                <ul>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Waiter/Waitress</Link></li>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Housekeeping</Link></li>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Chef Helper</Link></li>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Food Runner</Link></li>
                                    <li><Link to="/"><i className="fa fa-angle-right"></i>Strewarding Staff</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100093297050706&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=697618428209516" width="340" height="340" style={{border:"none", overflow:"hidden"}} frameorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copy-right-parent">
                                <div className="copy-right-childs">
                                    <p>Copyright © {new Date().getFullYear()} Zaweyat Alnada Facility Management</p>
                                </div>
                                <div className="copy-right-childs">
                                    <p>Developed by <a href="https://codesastra.com.np/" target="_blank" rel="noreferrer">InfosisDevelopers</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* footer::end */}
        </>
    )
}

export default Footer;
import React from 'react';
import Services from './Services';
import Counter from './Counter';
import About from './About';
import Contact from './Contact';
import Banner from './Banner';

const HomePage = () => {
    return (
        <>
            <Banner />
            <About />
            <Services />
            <Counter />
            <Contact />
        </>
    )
}

export default HomePage;
import React from 'react'
import Breadcrumb from '../components/Breadcrumb'

const Contact = () => {
    return (
        <>
            <Breadcrumb title="Contact Us" />
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-page-wrapper">
                                <div className="contact-information">
                                    <i className="fa fa-phone"></i>
                                    <h3>+971 50 393 5661</h3>
                                </div>
                            </div>
                            <div className="contact-page-wrapper">
                                <div className="contact-information">
                                    <i className="fa fa-envelope"></i>
                                    <h3>info@alnadadubai.com</h3>
                                </div>
                            </div>
                            <div className="contact-page-wrapper">
                                <div className="contact-information">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <h3>AL Muteena, Dubai - UAE</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <form>
                                    <h3>Please feel free to contact us</h3>
                                    <p>We will contact you with in 24 hours</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Full Name</label>
                                            <input type="text" placeholder="Enter your name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Phone</label>
                                            <input type="text" placeholder="+971 - " />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <label>Email Address</label>
                                            <input type="text" placeholder="info@alnadadubai.com" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Address</label>
                                            <input type="text" placeholder="UAE-United Arab Emirates" />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <label>Message/Feedback</label>
                                            <textarea rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <button className="submit-btn">Send Message <i className="fa fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
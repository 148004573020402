import React from 'react';
import zaweyat_alnada_about from "../assets/images/services/chef_helper.jpg";
import deco1 from "../assets/images/deco/purplebg.png";
import deco2 from "../assets/images/deco/purplebg.png";
import Breadcrumb from '../components/Breadcrumb';

const About = () => {
    return (
        <>
            <Breadcrumb title="About"/>
            <section className="zaweyat-alnada-about-us-section">
                <div className="about-us-deco-images">
                    <img src={deco1} alt="zaweyat_alnada_deco" className="img_deo_1" />
                    <img src={deco2} alt="zaweyat_alnada_deco" className="img_deo_2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="zaf-title">
                                <h3>ABOUT US</h3>
                                <p>Know more about us</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-us-parent">
                                <div className="about-us-child">
                                    <div className="about-feature-image">
                                        <img src={zaweyat_alnada_about} alt="about-featured" draggable={false} />
                                    </div>
                                </div>
                                <div className="about-us-child">
                                    <div className="about-description">
                                        <h3>Leading Facility Provider In UAE</h3>
                                        <p>
                                            We are a leading facility management company specializing in enhancing the efficiency and sustainability of diverse facilities. Our customer-centric approach, advanced technologies, and tailored strategies streamline operations, maximize uptime, and optimize resource allocation. From maintenance to energy management and security solutions, we provide comprehensive services that drive operational efficiency. With a commitment to continuous improvement, we deliver exceptional value, building long-lasting partnerships with our clients.
                                        </p>
                                    </div>
                                    <div className="zaweyat-alnada-facility-why-choose-parent">
                                        <div className="zaweyat-alnada-facility-why-choose-childs">
                                            <div className="why-choose-us-icon">
                                                <i className="fa fa-calendar-alt"></i>
                                            </div>
                                            <h3>4 YEARS OF EXPERIENCE</h3>
                                        </div>
                                        <div className="zaweyat-alnada-facility-why-choose-childs">
                                            <div className="why-choose-us-icon">
                                                <i className="fa fa-check-circle"></i>
                                            </div>
                                            <h3>RELIABLE SERVICES</h3>
                                        </div>
                                        <div className="zaweyat-alnada-facility-why-choose-childs">
                                            <div className="why-choose-us-icon">
                                                <i className="fa fa-shield-alt"></i>
                                            </div>
                                            <h3>QUALIFIED TEAM</h3>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;
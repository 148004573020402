import React from 'react';
import img1 from "../assets/images/agreement/agreement.png";

const Agreement = () => {
  return (
    <div className="agreement">
        <img src={img1} alt="agreement" />
    </div>
  )
}

export default Agreement
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import HomePage from './pages/HomePage';
import Navigation from './components/header/Navigation';
import Footer from './components/footer/Footer';
import Contact from './pages/Contact';
import CommingSoon from './pages/CommingSoon';

const App = () => {
  
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/underConstruction" element={<CommingSoon />} exact />
      </Routes>
      <Footer />
    </>
  )
}

export default App
import React from 'react';
import img11 from '../assets/images/services/housekeeping.jpg'
import img22 from '../assets/images/services/food_runner.png'
import img33 from '../assets/images/services/waiter.jpg'
import img44 from '../assets/images/services/party_helper.jpg'
import img55 from '../assets/images/services/diswasher.jpg'
import img66 from '../assets/images/services/chef_helper.jpg'

const Services = () => {
    return (
        <section className="services-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="zaf-title">
                            <h3>OUR SERVICES</h3>
                            <p>What we offer</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-wraper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img11} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>HOUSE KEEPING</h1>
                                    <p>
                                        Our meticulous housekeeping services create clean, organized spaces that promote well-being and leave a positive impression.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img22} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>FOOD RUNNER</h1>
                                    <p>
                                        Food runners ensure efficient and timely delivery of dishes from the kitchen to the dining area, ensuring a seamless dining experience.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img33} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>WAITER/WAITRESS</h1>
                                    <p>
                                        Waiters and waitresses provide attentive and friendly service, taking orders, serving food and beverages, and ensuring an enjoyable dining experience.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img44} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>Party Helper</h1>
                                    <p>
                                        Party helpers assist in organizing and executing successful events, handling tasks such as setting up decorations, serving guests, and ensuring smooth operations.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img55} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>STEWARDING STAFF</h1>
                                    <p>
                                        Stewarding staff play a crucial role in maintaining cleanliness and organization in hospitality establishments, ensuring efficient dishwashing, sanitation, and equipment maintenance.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-container">
                                <div className="services-inner-wrapper">
                                    <img src={img66} alt="services-image" />
                                </div>
                                <div className="services-info">
                                    <h1>CHEF HELPER</h1>
                                    <p>
                                        Chef helpers support chefs in various kitchen tasks, such as food preparation, ingredient gathering, and maintaining cleanliness, contributing to the smooth operation of the culinary team.
                                    </p>
                                    <div className="services-know-more">
                                        <a href="#">Know More <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;
import React from 'react';
import commingSoonImage from "../assets/images/comming_soon_page/comming_soon.PNG";

const CommingSoon = () => {
  return (
    <div className="comming-soon-page">
        <img src={commingSoonImage} alt="infosisdevelopers" />
    </div>
  )
}

export default CommingSoon;
import React from 'react';
import zaweyat_alnada_presentation from "../assets/images/promotional_video/zaweyat_alnada_presentation.mp4"

const Counter = () => {
    return (
        <>
            <section className="zaweyat-alnada-counter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="zaweyat-alnada-video-wrapper">
                                <h3>KNOW MORE ABOUT US</h3>
                                <video src={zaweyat_alnada_presentation} controls></video>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="zaweyat-alnada-counter">
                                <div className="counter-parent">
                                    <div className="counter-childs">
                                        <h1><i className="fa fa-users"></i> 102 +</h1>
                                        <p>CLIENTS</p>
                                    </div>
                                    <div className="counter-childs">
                                        <h1><i className="fa fa-user"></i> 200 +</h1>
                                        <p>WORKING EMPLOYEE</p>
                                    </div>
                                </div>
                                <div className="counter-parent">
                                    <div className="counter-childs">
                                        <h1><i className="fa fa-cogs"></i> 8 +</h1>
                                        <p>SERVICES</p>
                                    </div>
                                    <div className="counter-childs absoluteme">
                                        <h1><i className="fa fa-calendar-alt"></i> 4 +</h1>
                                        <p>YEARS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter;
import React, { useEffect, useState } from 'react';
import breadcrumb_video from "../assets/images/promotional_video/breadcrumb_video.mp4"
import { useLocation } from 'react-router-dom';

const Breadcrumb = ({ title }) => {
    const location = useLocation();

    const [showMe, setShowMe] = useState(true);

    useEffect(() => {
        if (location.pathname === "/") {
            setShowMe(true);
        } else {
            setShowMe(false);
        }
    }, [location.pathname])
    return (
        <>{
            showMe ?
                <></>
                :
                <>
                    <section className="zaweyat-alnada-facility-breadcrumb">
                        <div className="breadcrumb-info">
                            <h2>ZAWEYAT ALNADA FACILITY MANAGEMENT</h2>
                            <p>alnadadubai.com <i className="fa fa-angle-right"></i> {title}</p>
                        </div>
                        <div className="video-container">
                            <video loop="true" autoplay="autoplay" muted>
                                <source src={breadcrumb_video} type="video/mp4" />
                            </video>
                        </div>
                    </section>
                </>
        }
        </>
    )
}

export default Breadcrumb
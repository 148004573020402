import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Banner = () => {
    const navigate = useNavigate();

    const sendMeToAbout = () => {
        return navigate("/about");
    }

    const sendMeToContact = () => {
        return navigate("/contact");
    }

    return (
        <>
            <section className="zaweyat-alnada-banner-section">
                <div class="snowy-effect">
                    <div class="snow layer1 first-snow"></div>
                    <div class="snow layer1"></div>
                    <div class="snow layer2 first-snow"></div>
                    <div class="snow layer2"></div>
                    <div class="snow layer3 first-snow"></div>
                    <div class="snow layer3"></div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="zaweyat-alnada-banner-info">
                                <h3>ZAWEYT ALNADA FACILITY MANAGEMENT</h3>
                                <p>Leading Facility Provider</p>
                                <div className="zaweyat-alnada-facility-social-media">
                                    <div className="zaweyat-alnada-facility-social-media-wrapper">
                                        <div className="zaweyat-alnada-facility-social-media-wrapper-inner">
                                            <Link to="https://www.facebook.com/profile.php?id=100093297050706" target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </Link>
                                        </div>
                                        <div className="zaweyat-alnada-facility-social-media-wrapper-inner">
                                            <Link to="https://www.linkedin.com/in/zaweyat-alnada-facility-management-5555a6279/" target="_blank">
                                                <i className="fab fa-linkedin"></i>
                                            </Link>
                                        </div>
                                        <div className="zaweyat-alnada-facility-social-media-wrapper-inner">
                                            <Link to="https://www.instagram.com/alnadafacility/" target="_blank">
                                                <i className="fab fa-instagram"></i>
                                            </Link>
                                        </div>
                                        <div className="zaweyat-alnada-facility-social-media-wrapper-inner">
                                            <Link to="https://www.tiktok.com/@alnadafacilityman" target="_blank">
                                                <i className="fab fa-tiktok"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="zaweyat-alnada-banner-action-btns">
                                    <button onClick={()=> sendMeToAbout()}>KNOW MORE</button>
                                    <button onClick={()=> sendMeToContact()}>CONTACT US</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner
import React, { useEffect, useState } from 'react';
import zaweyat_alnada_logo_white from "../../assets/images/zaf_brand/zaf_logo_white.png"
import zaweyat_alnada_logo_black from "../../assets/images/zaf_brand/zaf_logo.png"
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
    const location = useLocation();

    const [showMe, setShowMe] = useState(true);

    useEffect(() => {
        if (location.pathname === "/") {
            setShowMe(true);
        } else {
            setShowMe(false);
        }
    }, [location.pathname])
    return (
        <>
            <section className={showMe ? "zaweyat-alnada-navigation-section " : "zaweyat-alnada-navigation-section navigation_alignment"}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="navigation-parent">
                                <div className="navigation-childs">
                                    <Link to="/">
                                        {
                                            showMe
                                                ?
                                                <img src={zaweyat_alnada_logo_white} alt="zaweyat_alnada_logo" draggable={false} />
                                                :
                                                <img src={zaweyat_alnada_logo_black} alt="zaweyat_alnada_logo" draggable={false} />
                                        }
                                    </Link>
                                </div>
                                <div className="navigation-childs">
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link className={showMe ? "" : "changeColor"} to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link className={showMe ? "" : "changeColor"} to="/about">About</Link>
                                            </li>
                                            <li>
                                                <Link className={showMe ? "" : "changeColor"} to="/underConstruction">Portfolio</Link>
                                            </li>
                                            <li>
                                                <Link className={showMe ? "" : "changeColor"} to="/underConstruction">Galley</Link>
                                            </li>
                                            <li>
                                                <Link className={showMe ? "" : "changeColor"} to="/contact">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navigation